import algoliasearch from 'algoliasearch'
import instantsearch from 'instantsearch.js'
import {
  refinementList,
  configure,
  pagination,
  clearRefinements,
  hierarchicalMenu,
} from 'instantsearch.js/es/widgets'
import historyRouter from 'instantsearch.js/es/lib/routers/history'
import { connectHits, connectSearchBox } from 'instantsearch.js/es/connectors'

import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'

import '@algolia/autocomplete-theme-classic'

const algoliaClient = algoliasearch(
  'S1RFCMKDSO',
  '9dc8fb70a2e03fc0b090fe614bd414b5',
)
const instantSearchRouter = historyRouter()

const GATED_RESOURCE_CATEGORIES = ['CAD', 'Revit', 'SketchUp', 'Guide Specs'];
const GATED_RESOURCE_EXTENSIONS = ['skp', 'sat','dgn', 'dwg', 'dxf', 'rfa','rvt', 'rte', 'rft'];
const GATED_RESOURCE_FILENAME_PHRASE = ['Sketch', 'CAD', 'Revit']; 

window.dispatchEvent(
  new CustomEvent('algolia-ready', {
    detail: {
      client: algoliaClient,
    },
  }),
)

// Custom Algolia Results AKA "Hits" Widget
const renderHits = (renderOptions, isFirstRender) => {
  // Rendering logic
  const { hits, results, sendEvent, widgetParams } = renderOptions

  if (isFirstRender) {
  }

  // if(Object.keys(hits).length !== 0){
  window.dispatchEvent(
    new CustomEvent('algolia-render-hits', {
      detail: {
        hits,
        results,
      },
    }),
  )
  // }

  // you can return a string or nothing for the initial render
  // e.g. return `<ul>${hits.map((hit) => (`<li>${hit.title}</li>`))}</ul>`
}

const debouncePromise = (fn, time) => {
  let timerId = undefined

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}


const getFacets = (options) => {
  let facetWrappers = Array.from(document.querySelectorAll('.js-facet-wrapper'))
  return facetWrappers.map((facetWrapper) => {
    let attribute = facetWrapper.dataset.facet
    let slug = facetWrapper.dataset.facetRef

    const defaultOptions = {
      container: facetWrapper.querySelector('.js-facet'),
      attribute: attribute,
      limit: 100,
      // Do not use sortBy - The real facet ordering is handled in Scout plugin and AlgoliaEvents
      // sortBy: ['name:asc'],
      
      transformItems(items) {
        // all this logic is to preserve the facets even when 0 hits are returned
        const cleanAtt = attribute.replace('.', '')
        if(options && options.facets && options.facets[cleanAtt]){
          options.facets[cleanAtt].forEach((facet, index) => {
            const updatedItem = items.find(item => item.value === facet.value);
            if (updatedItem) {
              options.facets[cleanAtt][index] = {
                count: 0,
                ...updatedItem,
              };
            } else {
              options.facets[cleanAtt][index] = {
                ...options.facets[cleanAtt][index],
                count: 0,
              }
            }
          });

          window.dispatchEvent(
            new CustomEvent('product-facets-updated', {
              detail: {
                facet: slug,
                items: options.facets[cleanAtt],
                itemCount: options.facets[cleanAtt].length,
              },
            }),
          );
          // return options.facets[cleanAtt];
          return JSON.parse(JSON.stringify(options.facets[cleanAtt]));
        }
        window.dispatchEvent(
          new CustomEvent('product-facets-updated', {
            detail: {
              facet: slug,
              items: items,
              itemCount: items.length,
            },
          }),
        );

        return items;
      },
      
    }

    return refinementList({
      ...defaultOptions,
      ...options,
    })
  })
}

const getTranslatedFacets = async (alpine) => {
  let index = alpine.index;

  const attributesForFaceting = await algoliaClient.initIndex(index).getSettings().then(settings => {
    const facetWrapper = document.getElementById('facets-wrapper');
    return settings.renderingContent.facetOrdering.facets.order.map((attribute) => {
      const facetWrapper = document.getElementById('facets-wrapper');
      
      let normalizedAttribute = attribute.toLowerCase().replace(/[\s_]+/g, '-');
      let attributeWrapper = document.createElement('div');
      attributeWrapper.classList.add('js-translated-facet-wrapper');
      let heading = document.createElement('h5');
      heading.classList.add('t-body-base', 'text-[1rem]', 'font-bold', 'heading-'+ normalizedAttribute);
      heading.textContent = attribute;
      attributeWrapper.appendChild(heading);
      let facetPlaceholder = document.createElement('div');
      facetPlaceholder.classList.add('js-facet-placeholder-' + normalizedAttribute);
      attributeWrapper.appendChild(facetPlaceholder);
    
      facetWrapper.appendChild(attributeWrapper);
    
      alpine.algolia.addWidget(refinementList({
        container: facetPlaceholder,
        attribute: attribute,
        limit: 100,
      }))

      return attribute
    })
  });


  // alpine.algolia.refresh();
  alpine.cleanTranslatedFacets();
}

const customHits = connectHits(renderHits)

const debounced = debouncePromise((items) => Promise.resolve(items), 300)

// Set the InstantSearch index UI state from external events.
const setInstantSearchUiState = (indexUiState, algoliaInstance, index) => {
  algoliaInstance.setUiState((uiState) => ({
    ...uiState,
    [index]: {
      ...uiState[index],
      // We reset the page when the search state changes.
      page: 1,
      ...indexUiState,
    },
  }))
}

export const AlgoliaProductSearch = () => {
  let filters = ''

  let filterDiv = document.querySelector('#algolia-default-filters')
  if (filterDiv) {
    filters = filterDiv.dataset.filters
  }

  let defaultAlgoliaSearch = {
    filters: filters,
  }

  return {
    algolia: null,
    index: window.AlgoliaIndexPrefix + 'products',
    isTranslated: false,
    results: [],
    hits: [],
    cleanTranslatedFacets() {
      Array.from(document.querySelectorAll('.js-translated-facet-wrapper')).forEach((facetWrapper) => {
        let refinementList = facetWrapper.querySelector('.ais-RefinementList');
        if(refinementList && refinementList.classList.contains('ais-RefinementList--noRefinement')) {
          facetWrapper.querySelector('h5').classList.add('hidden')
        }
      })
    },
    init() {
      const alpine = this

      if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
        this.index = 'translated_products_es';
        this.isTranslated = true;
      }

      if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
        this.index = 'translated_products_fr';
        this.isTranslated = true;
      }

      if(this.isTranslated && filterDiv){
        // let categoryTitle = document.querySelector('h1');
        // let newCategoryTitle = categoryTitle.innerText;
        // filters = `(category:"*${newCategoryTitle}*")`;
        filters = filterDiv.dataset.productIds
        defaultAlgoliaSearch = {
          filters: filters,
        }

        console.log('using the following filter set: ', defaultAlgoliaSearch)
      }

      this.algolia = instantsearch({
        indexName: this.index,
        searchClient: algoliaClient,
        routing: instantSearchRouter,
        onStateChange({ uiState, setUiState }) {
          alpine.hits = this.renderState[alpine.index].hits
          setUiState(uiState)
          alpine.cleanTranslatedFacets();
        },
      })
      this.algolia.addWidgets([
        configure({
          hitsPerPage: 100,
          facetingAfterDistinct: true,
          ...defaultAlgoliaSearch,
        }),
        customHits({
          container: this.$refs.hits,
        }),
        // pagination({
        //   container: this.$refs.pagination,
        //   scrollTo: this.$refs.hits,
        //   showFirst: false,
        //   showLast: false,
        //   templates: {
        //     previous(page, { html, components, sendEvent }) {
        //       return html`${alpine.$refs['pagination-previous'].innerHTML}`
        //     },
        //     next(page, { html, components, sendEvent }) {
        //       return html`${alpine.$refs['pagination-next'].innerHTML}`
        //     },
        //   },
        // }),
        clearRefinements({
          container: this.$refs['clear-refinements'],
          templates: {
            resetLabel({ hasRefinements }, { html }) {
              return html`<span>${hasRefinements ? 'Clear Filters' : ''}</span>`
            },
          },
        }),
      ])

      if(this.isTranslated){
          getTranslatedFacets(alpine)
      } else {
        this.algolia.addWidgets([
          ...getFacets(alpine),
        ])
      }
      
      this.algolia.start()

      window.addEventListener('algolia-render-hits', (e) => {
        alpine.results = e.detail.results
        alpine.hits = e.detail.hits
      })
    },
  }
}

export const AlgoliaIdeasFilter = ({ showFilters }) => {
  let filters = ''

  let filterDiv = document.querySelector('#algolia-default-filters')
  if (filterDiv) {
    filters = filterDiv.dataset.filters
  }

  return {
    showFilters: showFilters,
    algolia: null,
    index: window.AlgoliaIndexPrefix + 'ideas',
    facets: null,
    results: [],
    hits: [],
    init() {
      const alpine = this
      const initialState = {}
      const preselectedFacets = this.$refs['preselected-facets']
      if (preselectedFacets) {
        initialState['refinementList'] = {
          ...JSON.parse(preselectedFacets.dataset.facets),
        }
      }

      this.algolia = instantsearch({
        indexName: this.index,
        searchClient: algoliaClient,
        routing: instantSearchRouter,
        initialUiState: {
          [this.index]: {
            ...initialState,
          },
        },
        onStateChange({ uiState, setUiState }) {
          alpine.hits = this.renderState[alpine.index].hits
          setUiState(uiState)
        },
      })
      this.algolia.addWidgets([
        configure({
          hitsPerPage: 12,
          facetingAfterDistinct: true,
          filters: filters,
        }),
        customHits({
          container: this.$refs.hits,
        }),
        ...getFacets(alpine),
        pagination({
          container: this.$refs.pagination,
          scrollTo: this.$refs.hits,
          showFirst: false,
          showLast: false,
          templates: {
            previous(page, { html, components, sendEvent }) {
              return html`${alpine.$refs['pagination-previous'].innerHTML}`
            },
            next(page, { html, components, sendEvent }) {
              return html`${alpine.$refs['pagination-next'].innerHTML}`
            },
          },
        }),
        clearRefinements({
          container: this.$refs['clear-refinements'],
          templates: {
            resetLabel({ hasRefinements }, { html }) {
              return html`<span>${hasRefinements ? 'Clear Filters' : ''}</span>`
            },
          },
        }),
      ])
      this.algolia.start()

      window.addEventListener('algolia-render-hits', (e) => {
        // console.log(e)
        alpine.results = e.detail.results
        alpine.hits = e.detail.hits
      })

      this.algolia.on('render', (e) => {
        let itemsWithZero = Array.from(document.querySelectorAll('.ais-RefinementList-count')).filter((el) => el.textContent === '0');

        itemsWithZero.forEach((el) => {
          el.closest('li').classList.add('opacity-50')
        })
      })
    },
  }
}

export const AlgoliaSearchPage = () => {

  let index = window.AlgoliaIndexPrefix + 'sitesearch';

  if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
    index = 'translated_sitesearch_es';
  }

  if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
    index = 'translated_sitesearch_fr';
  }

  const getSources = (alpine) =>
    debounced([
      {
        sourceId: 'predefinedResults',
        getItems({ query }) {
          if (!query) {
            return [
              {
                title: 'Resources',
                url: '/resources',
              },
              {
                title: 'Furniture',
                url: '/furniture',
              },
              {
                title: 'Lighting',
                url: '/lighting',
              },
              {
                title: 'Collections',
                url: '/collections',
              },
            ]
          }

          return []
        },
        getItemUrl({ item }) {
          return item.url
        },
        templates: {
          header() {
            return 'Suggestions'
          },
          item({ item, html }) {
            return `${item.title}`
          },
        },
      },
      {
        sourceId: 'sitesearch',
        getItems({ query }) {
          if (!query) {
            return []
          }

          let index = window.AlgoliaIndexPrefix + 'sitesearch';

          if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
            this.index = 'translated_sitesearch_es';
          }
    
          if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
            this.index = 'translated_sitesearch_fr';
          }

          return getAlgoliaResults({
            searchClient: algoliaClient,
            queries: [
              {
                indexName: index,
                query,
                params: {
                  hitsPerPage: 5,
                },
              },
            ],
          })
        },
        getItemUrl({ item }) {
          return item.url
        },
        templates: {
          header() {
            return 'Search Results'
          },
          item({ item, html }) {
            switch (item.section) {
              case 'Resource':
                return html`
                  <a href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section} - .${item.extension}</p>
                    </div>
                  </a>
                `
              default:
                return html`
                  <a href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section}</p>
                    </div>
                  </a>
                `
            }
          },
        },
      },
    ])

  // Mount a virtual search box to manipulate InstantSearch's `query` UI
  // state parameter.
  const virtualSearchBox = connectSearchBox(() => {})

  // Return the InstantSearch index UI state.
  const getInstantSearchUiState = () => {
    const uiState = instantSearchRouter.read()

    return (uiState && uiState[index]) || {}
  }

  const searchPageState = getInstantSearchUiState()
  let skipInstantSearchUiStateUpdate = false

  const createAutocompleteInstance = (alpine) => {
    return autocomplete({
      container: alpine.$refs.autocomplete,
      placeholder: 'Search the site',
      detachedMediaQuery: 'none',
      openOnFocus: false,
      shouldPanelOpen() {
        return false
      },
      // TODO We probably want to get user consent for this
      // https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/#persistent-user-token
      insights: true,
      initialState: {
        query: searchPageState.query || '',
      },
      getSources() {
        return getSources(alpine)
      },
      onSubmit({ state }) {
        setInstantSearchUiState(
          { query: state.query },
          alpine.algolia,
          index,
        )
      },
      onReset() {
        setInstantSearchUiState({ query: '' }, alpine.algolia, index)
      },
      // Enable this to have instantsearch update as you type
      // onStateChange({ prevState, state }) {
      //   if (!skipInstantSearchUiStateUpdate && prevState.query !== state.query) {
      //     setInstantSearchUiState({ query: state.query });
      //   }
      //   skipInstantSearchUiStateUpdate = false;
      // },
    })
  }

  return {
    algolia: null,
    autocomplete: null,
    index: window.AlgoliaIndexPrefix + 'sitesearch',
    autcompleteResults: [],
    results: [],
    hits: [],
    init() {
      const alpine = this

      if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
        this.index = 'translated_sitesearch_es';
      }

      if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
        this.index = 'translated_sitesearch_fr';
      }

      this.algolia = instantsearch({
        searchClient: algoliaClient,
        indexName: alpine.index,
        routing: instantSearchRouter,
        onStateChange({ uiState, setUiState }) {
          alpine.hits = this.renderState[alpine.index].hits
          setUiState(uiState)
        },
      })
      this.algolia.addWidgets([
        virtualSearchBox({}),
        customHits({
          container: this.$refs.hits,
        }),
        ...getFacets(),
        pagination({
          container: this.$refs.pagination,
          scrollTo: this.$refs.hits,
          showFirst: false,
          showLast: false,
          templates: {
            previous(page, { html, components, sendEvent }) {
              return html`${alpine.$refs['pagination-previous'].innerHTML}`
            },
            next(page, { html, components, sendEvent }) {
              return html`${alpine.$refs['pagination-next'].innerHTML}`
            },
          },
        }),
      ])

      this.algolia.start()

      window.addEventListener('algolia-render-hits', (e) => {
        alpine.results = e.detail.results
        alpine.hits = e.detail.hits
      })

      // This keeps Autocomplete aware of state changes coming from routing
      // and updates its query accordingly
      window.addEventListener('popstate', () => {
        skipInstantSearchUiStateUpdate = true
        setQuery(alpine.algolia.helper?.state.query || '')
      })

      window.addEventListener('nav-algolia-opened', (e) => {
        alpine.autocomplete.destroy()
      })
      window.addEventListener('nav-algolia-closed', () => {
        alpine.autocomplete = createAutocompleteInstance(alpine)
      })

      alpine.autocomplete = createAutocompleteInstance(alpine)
    },
    getProductHits() {
      if(!this.hits.length) return [];
      return this.hits.filter((hit) => hit.section === 'Product')
    },
    getNonProductHits() {
      if(!this.hits.length) return [];
      return this.hits.filter((hit) => hit.section !== 'Product')
    }
  }
}

export const AlgoliaResourceSearch = ({ showResourceTypes }) => {
  // Mount a virtual search box to manipulate InstantSearch's `query` UI
  // state parameter.
  const virtualSearchBox = connectSearchBox(() => {})

  let index = window.AlgoliaIndexPrefix + 'resources';

  if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
    index = 'translated_resources_es';
  }

  if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
    index = 'translated_resources_fr';
  }

  // Return the InstantSearch index UI state.
  const getInstantSearchUiState = () => {
    const uiState = instantSearchRouter.read()

    return (uiState && uiState[index]) || {}
  }

  const searchPageState = getInstantSearchUiState()
  
  let skipInstantSearchUiStateUpdate = false

  const getSources = (alpine, { setQuery, refresh }) =>
    debounced([
      {
        sourceId: 'productsAndCategories',
        getItems({ query }) {
          if (query) {
            // return sourceData.filter(({ title }) =>
            //   title.toLowerCase().includes(query.toLowerCase()),
            // )
            return getAlgoliaResults({
              searchClient: algoliaClient,
              queries: [
                {
                  indexName: index,
                  query,
                  params: {
                    hitsPerPage: 8,
                    clickAnalytics: true,
                  },
                },
              ],
            })
          }
          return []
        },
        getItemUrl({ item }) {
          return item.url
        },
        onSelect({ item }) {
          setQuery(item.title)
          setInstantSearchUiState(
            { query: item.title },
            alpine.algolia,
            alpine.index,
          )
          alpine.showResourceTypes = false
          alpine.query = item.title
          refresh()
        },
        templates: {
          header() {
            return false
          },
          item({ item, html }) {
            let itemCategories = item.resourceCategories;

            switch (item.section) {
              case 'Recurso': // Spanish "Resource"
              case 'Resource':
                let signedIn = window.Alpine.store('user').currentUser;
                let requiresLogin = 
                GATED_RESOURCE_CATEGORIES.some(
                  gatedCategory => itemCategories.includes(gatedCategory)
                ) || 
                GATED_RESOURCE_EXTENSIONS.some(
                  gatedExtension => item.extension === gatedExtension.toLowerCase()
                ) ||
                GATED_RESOURCE_FILENAME_PHRASE.some(
                  gatedPhrase => item.title.indexOf(gatedPhrase) !== -1
                );
                if(requiresLogin && !signedIn){

                  return html`
                    <a href="/login?message=download-attempt&redirect=${window.getGatedResourceRedirect(item.url, item.filename)}">
                      <img src="${item.image}" />
                      <div>
                        <h5>${item.title}</h5>
                        <p>${item.section} - .${item.extension}</p>
                      </div>
                    </a>
                  `
                }
                return html`
                  <a class="algolia-resource-search-item" href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section} - .${item.extension}</p>
                    </div>
                  </a>
                `
              default:
                return html`
                  <a class="algolia-resource-search-item" href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section}</p>
                    </div>
                  </a>
                `
            }
          },
        },
      },
    ])

  let filters = ''

  let filterDiv = document.querySelector('#algolia-default-filters')
  if (filterDiv) {
    filters = filterDiv.dataset.filters
  }

  const createAutocompleteInstance = (alpine) => {
    return autocomplete({
      container: alpine.$refs.autocomplete,
      placeholder: 'Search by product or category',
      detachedMediaQuery: 'none',
      openOnFocus: true,
      classNames: {
        input: 'aa-Input--Resources',
        form: 'h-full',
        root: 'aa-Autocomplete--Resources h-full'
      },
      initialState: {
        query: searchPageState.query || '',
      },
      getSources({ setQuery, refresh }) {
        return getSources(alpine, { setQuery, refresh })
      },
      onSubmit({ state }) {
        setInstantSearchUiState(
          { query: state.query },
          alpine.algolia,
          alpine.index,
        )
        alpine.showResourceTypes = false
        alpine.query = state.query
      },
      onReset() {
        setInstantSearchUiState({ query: '' }, alpine.algolia, index)
        alpine.query = ''
      },
    })
  }

  return {
    algolia: null,
    autocomplete: null,
    showResourceTypes: showResourceTypes,
    showFilters: true,
    index: window.AlgoliaIndexPrefix + 'resources_sort_asc',
    autcompleteResults: [],
    facets: {},
    results: [],
    hits: [],
    query: '',
    init() {
      const alpine = this
      // if this ref isn't present, we're not ready to show any search results

      if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
        this.index = 'translated_resources_es_sort_asc';
      }

      if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
        this.index = 'translated_resources_fr_sort_asc';
      }

      if (this.$refs.hits) {
        const initialState = {}
        const preselectedFacets = this.$refs['preselected-facets']
        if (preselectedFacets) {
          initialState['refinementList'] = {
            ...JSON.parse(preselectedFacets.dataset.facets),
          }
        }

        this.algolia = instantsearch({
          searchClient: algoliaClient,
          indexName: this.index,
          routing: instantSearchRouter,
          initialUiState: {
            [this.index]: {
              ...initialState,
            },
          },
        })
        this.algolia.addWidgets([
          virtualSearchBox({}),
          configure({
            facetingAfterDistinct: true,
            filters: filters,
          }),
          customHits({
            container: this.$refs.hits,
          }),
          ...getFacets(alpine),
          clearRefinements({
            container: this.$refs['clear-refinements'],
            templates: {
              resetLabel({ hasRefinements }, { html }) {
                return html`<span>${hasRefinements ? 'Clear Filters' : ''}</span>`
              },
            },
          }),
          pagination({
            container: this.$refs.pagination,
            scrollTo: this.$refs.hits,
            showFirst: false,
            showLast: false,
            templates: {
              previous(page, { html, components, sendEvent }) {
                return html`${alpine.$refs['pagination-previous'].innerHTML}`
              },
              next(page, { html, components, sendEvent }) {
                return html`${alpine.$refs['pagination-next'].innerHTML}`
              },
            },
          }),
        ])

        this.algolia.start()

        this.algolia.on('render', (e) => {
          let itemsWithZero = Array.from(document.querySelectorAll('.ais-RefinementList-count')).filter((el) => el.textContent === '0');
          itemsWithZero.forEach((el) => {
            el.closest('li').classList.add('opacity-50')
          })
        })
      }

      this.$refs['clear-refinements'].addEventListener('click', () => {
        setInstantSearchUiState({ query: '' }, alpine.algolia, index)
        alpine.query = '';
        alpine.algolia.helper.setQuery('');
        alpine.autocomplete.setQuery('');
      })

      window.addEventListener('algolia-render-hits', (e) => {
        alpine.results = e.detail.results
        alpine.hits = e.detail.hits
      })

      // This keeps Autocomplete aware of state changes coming from routing
      // and updates its query accordingly
      window.addEventListener('popstate', () => {
        skipInstantSearchUiStateUpdate = true
        setQuery(alpine.algolia.helper?.state.query || '')
      })

      // We have the event listeners because we can only have one Autocomplete instance running at a time
      // there's an autocomplete searchbar in the main navigation so we have to kill the Resource searchbar while nav is open
      if (this.$refs.autocomplete && this.$refs['autocomplete-data']) {
        alpine.autocomplete = createAutocompleteInstance(alpine)

        window.addEventListener('nav-algolia-opened', () => {
          alpine.autocomplete.destroy()
        })
        window.addEventListener('navigation-searchbar-destroyed', () => {
          alpine.autocomplete = createAutocompleteInstance(alpine)
        })
      }
    },
  }
}

export const AlgoliaNavigationSearch = () => {
  
  let index = window.AlgoliaIndexPrefix + 'sitesearch';

  if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
    index = 'translated_sitesearch_es';
  }

  if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
    index = 'translated_sitesearch_fr';
  }

  const getSearchUrl = (query) => {
    return `/search?${index}[query]=${encodeURI(
      query,
    )}`
  }

  const getSources = () =>
    debounced([
      {
        sourceId: 'sitesearch',
        getItems({ query }) {
          if (!query) {
            return []
          }

          let indexName = window.AlgoliaIndexPrefix + 'sitesearch';

          if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
            this.index = 'translated_sitesearch_es';
          }
    
          if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
            this.index = 'translated_sitesearch_fr';
          }

          return getAlgoliaResults({
            searchClient: algoliaClient,
            queries: [
              {
                indexName: index,
                query,
                params: {
                  hitsPerPage: 5,
                  clickAnalytics: true,
                },
              },
            ],
          })
        },
        getItemUrl({ item }) {
          return item.url
        },
        templates: {
          header() {
            return false
          },
          item({ item, html }) {
            let itemCategories = item.resourceCategories;

            switch (item.section) {
              case 'Recurso': 
              case 'Resource':
                let signedIn = window.Alpine.store('user').currentUser;
                let requiresLogin = 
                GATED_RESOURCE_CATEGORIES.some(
                  gatedCategory => itemCategories.includes(gatedCategory)
                ) || 
                GATED_RESOURCE_EXTENSIONS.some(
                  gatedExtension => item.extension === gatedExtension.toLowerCase()
                ) ||
                GATED_RESOURCE_FILENAME_PHRASE.some(
                  gatedPhrase => item.title.indexOf(gatedPhrase) !== -1
                );
                if(requiresLogin && !signedIn){
                  return html`
                    <a href="/login?message=download-attempt&redirect=${window.getGatedResourceRedirect(item.url, item.filename)}">
                      <img src="${item.image}" />
                      <div>
                        <h5>${item.title}</h5>
                        <p>${item.section} - .${item.extension}</p>
                      </div>
                    </a>
                  `
                }
                return html`
                  <a href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section} - .${item.extension}</p>
                    </div>
                  </a>
                `
              case 'Idea':
                return html`
                  <a href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.type}</p>
                    </div>
                  </a>
                `
              default:
                return html`
                  <a href="${item.url}">
                    <img src="${item.image}" />
                    <div>
                      <h5>${item.title}</h5>
                      <p>${item.section}</p>
                    </div>
                  </a>
                `
            }
          },
        },
      },
      {
        sourceId: 'searchRedirect',
        getItems({ query }) {
          if (query) {
            return [{ title: query, url: getSearchUrl(query) }]
          }

          return []
        },
        templates: {
          item({ item, html }) {
            return html`
              <a href="${item.url}">See all results for “${item.title}”</a>
            `
          },
        },
      },
    ])

  // Return the InstantSearch index UI state.
  const getInstantSearchUiState = () => {
    const uiState = instantSearchRouter.read()

    return (uiState && uiState[index]) || {}
  }

  const searchPageState = getInstantSearchUiState()

  window.navigationSearch = null

  const createNavigationSearch = (container) => {
    return autocomplete({
      container: container,
      detachedMediaQuery: 'none',
      openOnFocus: true,
      autoFocus: true,
      // TODO We probably want to get user consent for this
      // https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/#persistent-user-token
      insights: true,
      onStateChange(e) {
        if (e.state.query) {
          document.body.classList.add('aa-panel-open')
        } else {
          document.body.classList.remove('aa-panel-open')
        }
      },
      onSubmit({ state }) {

        let index = `${window.AlgoliaIndexPrefix}sitesearch`;

        if(window.location.hostname.indexOf('es.') !== -1 || window.location.hostname.indexOf('es-staging.') !== -1){
          index = 'translated_sitesearch_es';
        }
  
        if(window.location.hostname.indexOf('fr.') !== -1 || window.location.hostname.indexOf('fr-staging.') !== -1){
          index = 'translated_sitesearch_fr';
        }
        // change URL to /search
        
        window.location.href = `/search?${index}[query]=${encodeURI(
          state.query,
        )}`
      },
      // initialState: {
      //   query: searchPageState.query || '',
      // },
      getSources() {
        return getSources()
      },
    })
  }

  // We have the event listeners because we can only have one Autocomplete instance running at a time
  // we want to kill the navigation searchbar while nav is closed so that other pages can utilize Autocomplete as needed
  window.addEventListener('nav-algolia-opened', (e) => {
    
    const isDesktop = e.detail.screen === 'desktop'
    const isMobile = e.detail.screen === 'mobile'

    if(isDesktop){
      if (!window.navigationSearch) {
        window.navigationSearch = createNavigationSearch('#algolia-nav-search')
        document.body.classList.add('aa-panel-open')
        if(document.querySelector('.aa-Input')){
          document.querySelector('.aa-Input').focus()
        }
      }
    }
    
    if(isMobile){
      if (!window.navigationSearchMobile) {
        window.navigationSearchMobile = createNavigationSearch('#algolia-nav-search-mobile')
        document.body.classList.add('aa-panel-open')
        if(document.querySelector('.aa-Input')){
          document.querySelector('.aa-Input').focus()
        }
      }
    }

  })

  window.addEventListener('nav-algolia-closed', (e) => {
    document.body.classList.remove('aa-panel-open')

    if (window.navigationSearch) {
      window.navigationSearch.destroy()
      delete window.navigationSearch
      window.dispatchEvent(
        new CustomEvent('navigation-searchbar-destroyed', {}),
      )
    }

    if (window.navigationSearchMobile) {
      window.navigationSearchMobile.destroy()
      delete window.navigationSearchMobile
      window.dispatchEvent(
        new CustomEvent('navigation-searchbar-destroyed', {}),
      )
    }
    
  })
}
